export default {
	connectezVous: 'Connectez-vous',
	withQrp: 'Connectez-vous avec vos identifiants QRP',
	firstConnexion: 'Première connexion ? ',
	forgottenPwd: 'Mot de passe oublié ?',
	seConnecter: 'Se connecter',
	email: 'Adresse email',
	password: 'Mot de passe',
	error404: 'Email ou mot de passe erroné'
};
