export default {
	state: {
		0: 'En attente de validation',
		1: 'Actif',
		2: 'Inactif'
	},
	liste: 'Liste des utilisateurs secondaires',
	enregistrer: 'Enregistrer nouvel utilisateur secondaire',
	enregistrerCourt: 'Enregistrer',
	ajouter: 'Ajouter un nouvel utilisateur secondaire',
	supprimer: 'Supprimer',
	relancer: 'Relancer',
	sirets: 'Liste SIRETS',
	confirmation: 'Confirmer la suppression de l\'utilisateur: ',
	annuler: 'Annuler',
	confirmer: 'Confirmer',
	gestion: 'Gestion des utilisateurs secondaires',
	suppression: 'Suppression d\'un utilisateur secondaire',
	associerSirets: 'Cocher les SIRETS que vous souhaitez associer à ',
	dissocierSirets: 'Ou décocher les SIRETS que vous souhaitez lui dissocier'
};
