import Vue from 'vue';
import VueMatomo from 'vue-matomo';
import { router } from '@/router';

if (window.MATOMO_ENABLED === 'true') {
	Vue.use(VueMatomo, {
		host: window.MATOMO_URL,
		siteId: window.MATOMO_SITE_ID,
		trackerFileName: 'matomo',
		router
	});
}
