export const SLEEP_3000 = 3000;

export const TIME_OUT_1000 = 1000;

export const EXPIRATION = 600000;

export const MILLISECONDS_PER_HOUR = 3600000;

// 86400000 = (24 * 60 * 60 * 1000)
export const MILLISECONDS_PER_DAY = 86400000;
