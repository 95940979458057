export default {
	btn: 'J’ai compris',
	title: 'Identification impossible',
	FCIR_KO: {
		description1: 'Vos informations de connexion France Connect ne nous permettent pas de vous identifier.',
		description2: 'Nous vous invitons à créer votre compte à l’aide d’un code de déblocage.'
	},
	QRP_NULL: {
		description1: 'Nous n’avons trouvé aucun dossier vous concernant. Nous ne sommes donc pas en mesure de créer votre compte.',
		description2: 'Si vous avez reçu un courrier de la caisse primaire vous concernant, contactez le '
	},
	ERREUR_TECHNIQUE: {
		title: 'Erreur technique',
		description1: 'Une erreur est survenue, veuillez essayer ultérieurement.'
	},
	CHANGEMENT_EMAIL: {
		title: 'Vous avez été déconnecté pour permettre le changement d\'adresse email',
		description1: 'Afin de valider le changement d\'email associé à votre compte un lien de confirmation vous a été envoyé sur cette nouvelle adresse.',
		description2: 'La modification d\'email ne sera effective qu\'après avoir cliqué sur ce lien. Ce lien expirera dans 1 heure.',
		btn: 'Retour à l\'accueil'
	}
};
