export default {
	titre: ' Bienvenue sur le questionnaire risques professionnels en ligne',
	cardtitre: {
		assure: 'Vous êtes salarié',
		employeur: 'Vous êtes employeur',
		general: 'Vous êtes'
	},
	bouton: {
		assure: 'Salarié',
		employeur: 'Employeur',
		connexion: 'Se connecter',
		inscription: 'S\'inscrire',
		retour: 'Retour'
	},
	cardsoustitre: {
		connexion: 'Vous avez déjà un compte',
		inscription: 'Il s\'agit de votre première connexion, vous allez recevoir votre code de déblocage par courrier dans les prochains jours. Si c\'est le cas, cliquer ci-dessous'
	}
};
