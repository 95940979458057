export default {
	dateDpcm: 'Date de première constatation médicale (DPCM)',
	dateDeclarationSinistre: 'Date de déclaration du sinistre',
	retourDossier: 'Retour au dossier',
	retour: 'Retour',
	exportDocumentProvisoire: 'Exporter document provisoire',
	transmettreCpam: 'Transmettre à ma CPAM',
	message1: 'Un accident du travail se définit comme un accident survenu, quelle qu\'en soit la cause, par le fait ou à l\'occasion du travail à toute personne salariée ou travaillant, à quelque titre ou en quelque lieu que ce soit, pour un ou plusieurs employeurs ou chefs d\'entreprises (art L.411-1 du code de la Sécurité sociale). Pour déterminer si l\'accident cité en référence est un accident du travail, il appartient à la victime d\'apporter la preuve des circonstances de l\'accident autrement que par ses propres affirmations. Si l\'accident a eu lieu pendant le temps de travail et sur le lieu de travail, il est présumé être un accident du travail quelle qu\'en soit sa cause. ',
	message2: 'Afin d\'étudier au mieux la réalité des faits, merci de compléter ce questionnaire au plus vite et nous communiquer les attestations sur l\'honneur de témoins directs/indirects nécessaires à l\'instruction du dossier. Pour être recevables, ces attestations devront être accompagnées de la copie de la pièce d\'identité de son auteur. Sans réponse de votre part, nous statuerons avec les éléments présents au dossier.'
};
