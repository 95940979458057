export default {
	links: [
		{
			label: 'Accueil',
			to: {
				name: 'home'
			},
			options: {
				exact: true
			}
		},
		{
			label: 'À propos',
			to: {
				name: 'about'
			}
		}
	]
};
