
	import Vue from 'vue';
	import Component from 'vue-class-component';

	import { Meta, MetaInfo } from '@/decorators';

	// Static import for faster rendering
	import { AppHeader, AppFooter, MessageBar } from '@/components/layout';

	// Env var
	declare const MAINTENANCE: string;

	/** App is the main component */
	@Component({
		components: {
			AppHeader,
			AppFooter,
			MessageBar
		}
	})
	export default class App extends Vue {
		get maintenance(): boolean {
			return MAINTENANCE === 'true';
		}

		/** The meta informations of the route */
		/* istanbul ignore next */
		@Meta
		metaInfo(): MetaInfo {
			return {
				// If no sub-components specify a metaInfo.title, this title will be used
				title: this.$t('common.meta.title') as string,
				// All titles will be injected into this template
				titleTemplate: this.$t('common.meta.titleTemplate') as string,
				meta: [
					{
						name: 'description',
						content: this.$t('common.meta.description') as string
					}
				]
			};
		}
	}
