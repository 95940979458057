import WebFontLoader from 'webfontloader';

// Async fonts loading
WebFontLoader.load({
	google: {
		families: [
			'Open Sans:100,300,400,500,700,900&display=swap'
		]
	}
});
