
	import Vue from 'vue';
	import Component from 'vue-class-component';

	import { mapState } from 'vuex';
	import { RawLocation } from 'vue-router';

	/** The application footer */
	@Component({
		computed: mapState('appState', ['user'])
	})
	export default class AppFooter extends Vue {
		version = process.env.VUE_APP_VERSION;
		versionDate = process.env.VUE_APP_VERSION_DATE;
		popUpAide = false;

		get responsivePopUpWidth(): string {
			return this.$vuetify.breakpoint.smAndDown ? '80%' : '50%';
		}

		get versionLabel(): string {
			return this.version + ' de ' + this.versionDate;
		}

		get cguRoute(): RawLocation {
			return { name: 'cgu' };
		}
	}
