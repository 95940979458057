import { axios, axiosBff, AxiosResponse } from '@/plugins/axios';

import { InscriptionModel, PasswordModel, AjoutSiretModel, EmailModel, NewMotDePasseModel, LoginModel } from '@/services/compte/types';
import { EmployeurModel } from '../questionnaire/types';
import { ACCEPT_LD_JSON } from '@/constants/diversConstants';

export function creerCompte(userType: string, payload: InscriptionModel ): Promise<AxiosResponse> {
	return axios.post(`/inscription/${userType}`, payload);
}

export function validateUser(token: string): Promise<AxiosResponse> {
	return axios.get(`/validate-user/${token}`);
}

export function validateCgu(): Promise<AxiosResponse> {
	return axios.post('/api/validate/cgu');
}

export function forgottenPassword(email: string): Promise<AxiosResponse> {
	return axios.get(`/api/forgotten-password/${email}`);
}

export function resetPassword(token: string, payload: PasswordModel ): Promise<AxiosResponse> {
	return axios.post(`/api/reset-password/${token}`, payload);
}

export function checkToken(token: string): Promise<AxiosResponse> {
	return axios.get(`/api/check-token/${token}`);
}

export function ajoutSiret(payload: AjoutSiretModel): Promise<AxiosResponse> {
	return axios.post('/api/ajout-siret', payload);
}

export function getMyUser(): Promise<AxiosResponse> {
	return axios.get('/api/my-user', {
		headers: {
			accept: ACCEPT_LD_JSON
		}
	});
}

export function supprimerSiret(employeurId: string): Promise<AxiosResponse> {
	return axios.post(`/api/supprimer-siret/${employeurId}`);
}

export function getUsers(groupUser: string, page: number, itemsPerPage: number): Promise<AxiosResponse> {
	return axios.get(`/api/users-info?groupUser=${groupUser}&page=${page}&itemsPerPage=${itemsPerPage}`, {
		headers: {
			accept: ACCEPT_LD_JSON
		}
	});
}

export function ajouterUtilisateur(payload: EmailModel): Promise<AxiosResponse> {
	return axios.post('/api/ajout-utilisateur-secondaire', payload);
}

export function supprimerUtilisateur(userId: string): Promise<AxiosResponse> {
	return axios.post(`/api/supprimer-utilisateur-secondaire/${userId}`);
}

export function getEmployeursByUser(page?: number, itemsPerPage?: number): Promise<AxiosResponse> {
	let url = '/api/employeurs';
	if ( page && itemsPerPage ) {
		url += `?page=${page}&itemsPerPage=${itemsPerPage}`;
	}
	return axios.get(url, {
		headers: {
			accept: ACCEPT_LD_JSON
		}
	});
}

export function ajoutSiretUtilisateur(userId: string, employeurs: EmployeurModel[]): Promise<AxiosResponse> {
	return axios.post(`/api/ajout-siret-utilisateur/${userId}`, employeurs);
}

export function cederSirets(payload: EmailModel): Promise<AxiosResponse> {
	return axios.post('/api/ceder-sirets', payload);
}

export function modifierMotDePasse(payload: NewMotDePasseModel): Promise<AxiosResponse> {
	return axios.post('/api/modifier-mot-de-passe', payload);
}

export function sendEmailValidationNewEmail(payload: EmailModel): Promise<AxiosResponse> {
	return axios.post('/api/send-email-validation-new-email', payload);
}

export function validateNewEmail(token: string, payload: EmailModel): Promise<AxiosResponse> {
	return axios.post(`/api/validate-new-email/${token}`, payload);
}

export function connectByEmailPassword(payload: LoginModel): Promise<AxiosResponse> {
	return axiosBff.post('/bff/login', payload);
}

export function inscriptionCgu(token: string): Promise<AxiosResponse> {

	return axiosBff.post('/fc/inscription', null, {
		headers: {
			Authorization: token
		}
	});
}
