import { RawLocation, Route } from 'vue-router';
import { Next } from './types.d';

import { Authenticator } from '@/plugins/axios';

import { store } from '@/store';
import userInfoService from '@/services/userInfo/userInfoService';

import { UserInfo } from '@/types';

import { questionnaireService } from '@/services/questionnaire/api';
import { LocalStorageUtility } from '@cnamts/vue-dot/src/helpers/localStorageUtility';
import routeService from '@/services/RouteService';
import { TokenEnum } from '@/types/enum/TokenEnum';
import { RolesEnum } from '@/types/enum/RolesEnum';

declare const V4_URL: string;

export function updateAppState(user: null | UserInfo, logged: boolean, loaded: boolean): void {
	store.dispatch('appState/updateUser', user);
	store.dispatch('appState/updateLogged', logged);
	store.dispatch('appState/updateLoaded', loaded);
}

/** Clear app state & redirect to loginError page */
function loginError(next: Next, error?: unknown) {
	if (error) {
		updateAppState(null, false, false);
		next({ name: 'loginError' });
	}
}

export function logoutTemoin(redirectUrl: string): void {
	Authenticator.removeToken();
	userInfoService.deleteUserInfo();
	updateAppState(null, false, false);
	const ls = new LocalStorageUtility();
	ls.clear();
	window.location.href = Authenticator.logoutUri(RolesEnum.TEMOIN, redirectUrl);
}

function handleNotLoggedIn(to: Route, next: Next): void {
	// We need to check this after otherwise the previous condition
	// will fail and it will fall to "user already logged in"
	store.dispatch('appState/updateLoaded', false);
	Authenticator.removeToken();
	updateAppState(null, false, false);

	if (to.name !== 'home' && to.name !== 'login') {
		routeService.saveRedirectTo(to);
	}
	next({ name: 'login' });
}

function handleAccessToken(to: Route, next: Next): void {
	if (userInfoService.hasUserInfo()) {
		userInfoService.deleteUserInfo();
	}

	if (to.hash.includes('id_token_hint')) {
		const id_token_hint = to.hash.split('id_token_hint=')[1];
		Authenticator.saveToken(TokenEnum.ID_TOKEN_HINT, id_token_hint, false);
	}

	const saveUserInfoCallBack = () => {
		Authenticator.getUserInfo(
			(data: UserInfo) => {
				userInfoService.saveUserInfo(data);
				redirectToUserPage(next);
			},
			(error) => loginError(next, error)
		);
	};
	Authenticator.loginCallback(
		saveUserInfoCallBack,
		(error) => loginError(next, error)
	);
	next();
}

function redirectToUserPage(next: Next): void {
	const userType = userInfoService.getUserInfo()?.type;
	let to: RawLocation = { name: 'home' };

	switch (userType) {
		case RolesEnum.ASSURE:
			to = { name: 'dossierAssure' };
			break;
		case RolesEnum.EMPLOYEUR:
			to = { name: 'dossierEmployeur' };
			break;
		case RolesEnum.TEMOIN:
			questionnaireService.getIdQuestionnaireTemoin().then((response) => {
				if (response.data.idV4) {
					logoutTemoin(V4_URL);
				} else if (response.data.id) {
					// @ts-ignore
					next({ name: 'questionnaire', params: { reponseId: response.data.id }, replace: true });
				}
			});
			return;
		default:
			to = { name: 'home' };
			break;
	}

	if (routeService.hasRedirectTo()) {
		const goTo = routeService.getRedirectTo();
		if (goTo && goTo.name) {
			routeService.deleteRedirectTo();
			to = { name: goTo.name, params: goTo.params };
		}
	}
	next(to);
}

function handleLoggedIn(next: Next): void {
	const userInfo = userInfoService.getUserInfo();
	if (userInfo != null) {
		updateAppState(userInfo, true, true);
	}
	next();
}

export function authenticate(to: Route, from: Route, next: Next): void {
	/** Is the user is logged and we got his informations */
	const loggedIn = Authenticator.isLoggedIn() && userInfoService.hasUserInfo();
	// If the user isn't logged and don't have a token
	if (!to.hash.includes('#access_token') && !loggedIn) {
		handleNotLoggedIn(to, next);
	} else if (to.hash.includes('#access_token')) {
		handleAccessToken(to, next);
	} else {
		handleLoggedIn(next);
	}
}
