export default {
	inscriptionValid: {
		text: 'Votre compte a bien été activé'
	},
	inscriptionNonValid: {
		text1: 'Une erreur est survenue lors de la création de votre compte. ',
		text2: 'Celui-ci na pu être crée.',
		text3: 'Nous vous invitons à procéder de nouveau à la création de votre compte.'
	},
	redirection: 'Vous allez être redirigé vers la page',
	redirectionName: 'd\'accueil',
	temps: 'secondes'
};
