import components from './components';
import views from './views';
import common from './common';
import notification from './notification';

export default {
	components,
	views,
	common,
	notification
};
