// Disable return types for import functions
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// Directly import Home view for faster rendering of first page
import Home from '@/views/Home.vue';

/** See https://router.vuejs.org/fr/essentials/navigation.html for help */
export const routes = [
	{
		path: '/',
		name: 'home',
		component: Home
	},
	{
		path: '/login',
		name: 'login',
		component: () => import(
			/* webpackChunkName: "accessmaster" */
			'@/views/Login.vue'
			),
		meta: {
			logged: false
		}
	},
	{
		path: '/:userType/login',
		name: 'loginAssure',
		component: () => import(
			/* webpackChunkName: "loginAssure" */
			'@/views/franceConnect/AssureConnexionFC.vue'
		),
		meta: {
			logged: false
		}
	},
	{
		path: '/cgu',
		name: 'cgu',
		props: true,
		component: () => import(
			/* webpackChunkName: "cgu" */
			'@/views/Cgu.vue'
		),
		meta: {
			logged: false
		}
	},
	{
		path: '/update/cgu',
		name: 'updateCgu',
		component: () => import(
			/* webpackChunkName: "validate-cgu" */
			'@/views/ValidateCgu.vue'
			),
		props: { update: true },
		meta: {
			logged: true
		}
	},
	{
		path: '/inscription/cgu',
		name: 'inscriptionCgu',
		component: () => import(
			/* webpackChunkName: "validate-cgu" */
			'@/views/ValidateCgu.vue'
			),
		props: { update: false },
		meta: {
			logged: false
		}
	},
	{
		path: '/inscrivez-vous/assure',
		name: 'inscriptionAssure',
		component: () => import(
			/* webpackChunkName: "inscriptionAssure" */
			'@/views/franceConnect/AssureInscriptionFC.vue'
			),
		meta: {
			logged: false
		}
	},
	{
		path: '/inscription/:userType',
		name: 'inscription',
		props: true,
		component: () => import(
			/* webpackChunkName: "inscription" */
			'@/views/Inscription.vue'
		),
		meta: {
			logged: false
		}
	},
	{
		path: '/erreur/reconciliation/:type',
		name: 'reconciliationKo',
		props: true,
		component: () => import(
			/* webpackChunkName: "identificationImpossible" */
			'@/views/PageNotification.vue'
		),
		meta: {
			logged: false
		}
	},
	{
		path: '/changement-email/:type',
		name: 'changementEmailNotice',
		props: true,
		component: () => import(
			/* webpackChunkName: "identificationImpossible" */
			'@/views/PageNotification.vue'
		),
		meta: {
			logged: false
		}
	},
	{
		path: '/validation/:token',
		name: 'validationInscription',
		props: true,
		component: () => import(
			/* webpackChunkName: "validationInscription" */
			'@/views/ValidationInscription.vue'
		),
		meta: {
			logged: false
		}
	},
	{
		path: '/validation/nouvel-email/:newEmail/:token',
		name: 'validationNewEmail',
		props: true,
		component: () => import(
			/* webpackChunkName: "validationNewEmail" */
			'@/views/ValidationNewEmail.vue'
		),
		meta: {
			logged: false
		}
	},
	{
		path: '/mot-de-passe/oublie',
		name: 'motDePasseOublie',
		props: true,
		component: () => import(
			/* webpackChunkName: "motDePasseOublie" */
			'@/views/MotDePasseOublie.vue'
		),
		meta: {
			logged: false
		}
	},
	{
		path: '/mot-de-passe/reinitialisation/:token',
		name: 'motDePasseReinitialisation',
		props: true,
		component: () => import(
			/* webpackChunkName: "motDePasseReinitialisation" */
			'@/views/MotDePasseReinitialisation.vue'
		),
		meta: {
			logged: false
		}
	},
	{
		path: '/questionnaire/:reponseId',
		name: 'questionnaire',
		component: () => import(
			/* webpackChunkName: "questionnaire" */
			'@/views/Questionnaire.vue'
			)
	},
	{
		path: '/details-dossier/:dossierId',
		name: 'detailsDossier',
		props: true,
		component: () => import(
			/* webpackChunkName: "detailsDossier" */
			'@/views/DetailsDossier.vue'
		),
		meta: {
			logged: true
		}
	},
	{
		path: '',
		name: 'onglets',
		props: true,
		component: () => import(
			/* webpackChunkName: "employeur" */
			'@/views/onglets/Onglets.vue'
		),
		meta: {
			logged: true
		},
		children: [
			{
				path: '/employeur/dossiers',
				name: 'dossierEmployeur',
				component: () => import(
					/* webpackChunkName: "dossierEmployeur" */
					'@/views/dossiers/DossierEmployeur.vue'
				),
				meta: {
					logged: true
				}
			},
			{
				path: '/employeur/parametrage',
				name: 'parametrageEmployeur',
				component: () => import(
					/* webpackChunkName: "parametrageEmployeur" */
					'@/views/onglets/ParametrageEmployeur.vue'
				),
				meta: {
					logged: true
				}
			},
			{
				path: '/employeur/mon-compte',
				name: 'compteEmployeur',
				component: () => import(
					/* webpackChunkName: "compte" */
					'@/views/onglets/Compte.vue'
				),
				meta: {
					logged: true
				}
			},
			{
				path: '/assure/dossiers',
				name: 'dossierAssure',
				component: () => import(
					/* webpackChunkName: "dossierAssure" */
					'@/views/dossiers/DossierAssure.vue'
				),
				meta: {
					logged: true
				}
			},
			{
				path: '/assure/mon-compte',
				name: 'compteAssure',
				component: () => import(
					/* webpackChunkName: "compte" */
					'@/views/onglets/Compte.vue'
				),
				meta: {
					logged: true
				}
			}
		]
	},
	{
		path: '/maintenance',
		name: 'maintenance',
		component: () => import(
			/* webpackChunkName: "maintenance" */
			'@/views/Maintenance.vue'
		),
		meta: {
			logged: false
		}
	},
	{
		path: '*', // Everything that doesn't match a route
		name: 'notFound',
		component: () => import(
			/* webpackChunkName: "not-found" */
			'@/views/NotFound.vue'
		),
		meta: {
			logged: false
		}
	}
];
