export default {
	liste: 'Liste des SIRETS',
	enregistrer: 'Enregistrer nouveau SIRET',
	enregistrerCourt: 'Enregistrer',
	ajouter: 'Ajouter un SIRET',
	supprimer: 'Supprimer',
	confirmation: 'Confirmer la suppression du SIRET: ',
	annuler: 'Annuler',
	confirmer: 'Confirmer',
	setSiretToAll: 'Ajouter ce SIRET à tous les utilisateurs',
	gestion: 'Gestion des SIRETS',
	suppression: 'Suppression d\'un SIRET'
};
