export default {
	logoBtn: {
		label: 'Accueil',
		link: {
			name: 'home'
		},
		alt: 'l\'Assurance Maladie'
	},
	title: 'Questionnaires Risques Professionnels'
};
