export default {
	meta: {
		title: 'Questionnaires Risques Professionnels',
		description: 'Votre nouvelle application Vue Dash.'
	},
	title: 'Questionnaires Risques Professionnels',
	description: {
		block1: 'Vous venez de créer une nouvelle application front-end avec Vue Dash.',
		block2: 'Tout est prêt, vous pouvez dès à présent commencer à développer !'
	},
	about: {
		to: {
			name: 'about'
		},
		label: 'À propos'
	},
	notify: 'Envoyer une notification (exemple)'
};
