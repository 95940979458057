
	import Vue from 'vue';
	import Component from 'vue-class-component';

	import { Options } from '@cnamts/vue-dot/src/mixins/customizable';

	interface ToolbarLink {
		label: string;
		to?: {
			name: string;
		};
		options?: Options;
	}

	declare const MAINTENANCE: string;

	@Component
	export default class AppToolbar extends Vue {
		get links(): ToolbarLink[] {
			return this.$t('components.layout.appToolbar.links') as unknown as ToolbarLink[];
		}

		get maintenance(): boolean {
			return MAINTENANCE === 'true';
		}
	}
