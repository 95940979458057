export default {
	emailValid: {
		text: 'Votre adresse email a bien été modifiée'
	},
	emailNonValid: {
		text1: 'Une erreur est survenue lors de la validation de votre adresse email. ',
		text2: 'Celle-ci n\'a pu être modifiée.',
		text3: 'Nous vous invitons à renouveler l\'opération'
	},
	redirection: 'Vous allez être redirigé vers la page',
	redirectionName: 'd\'accueil',
	temps: 'secondes'
};
