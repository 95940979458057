import appFooter from './appFooter';
import appHeader from './appHeader';
import appToolbar from './appToolbar';
import headerMenu from './headerMenu';

export default {
	appFooter,
	appHeader,
	appToolbar,
	headerMenu
};
