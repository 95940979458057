import about from './about';
import home from './home';
import maintenance from './maintenance';
import notFound from './notFound';
import cgu from './cgu';
import inscription from './inscription';
import login from './login';
import validationNewEmail from './validationNewEmail';
import validationInscription from './validationInscription';
import detailsDossier from './detailsDossier';
import dossierEmployeurs from './dossierEmployeurs';
import pageNotification from './pageNotification';

export default {
	about,
	home,
	maintenance,
	notFound,
	cgu,
	inscription,
	validationNewEmail,
	validationInscription,
	login,
	detailsDossier,
	dossierEmployeurs,
	pageNotification
};
