export default {
	meta: {
		title: 'Questionnaires Risques Professionnels',
		titleTemplate: '%s | CNAM',
		description: 'consult-drp front salarié employeur.'
	},
	defaultErrorMessage: 'Une erreur inconnue est survenue. Merci de réitérer votre demande.',
	oauthErrorMessage: 'Vous êtes déconnecté',
	cguErrorMessage: 'Vous devez valider la dernière version des cgu'
};
