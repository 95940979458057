
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import { mapActions, mapState } from 'vuex';
	import { mdiInformation } from '@mdi/js';

	@Component({
		computed: mapState('messageUtilisateurs', ['messages']),
		methods: mapActions('messageUtilisateurs', ['updateMessageUtilisateurs'])
	})
	export default class MessageBar extends Vue {
		infoIcon = mdiInformation;
		mounted(): void {
			this.updateMessageUtilisateurs();
		}
	}
