export default {
	titre: 'Inscrivez-vous avec votre code de déblocage',
	nir: 'Numéro de sécurité sociale',
	siret: 'Siret',
	codeDeblocage: 'Code de déblocage',
	email: 'Adresse email',
	retour: 'Annuler',
	creer: {
		assure: 'Créer mon compte Salarié',
		employeur: 'Créer mon compte Employeur'
	},
	texte: 'Le code de déblocage est envoyé par courrier dans les 6 jours suivants votre demande. Si vous ne l’avez pas reçu ou pour faire une demande de code de déblocage, veuillez contacter le',
	isParticulierEmployeur: 'Cocher si vous êtes un particulier employeur',
	identifiant: 'Identifiant',
	cgu: 'J’ai lu et j’accepte les',
	cgu2: 'du téléservice Questionnaires Risques Professionnels (QRP) ',
	cguLink: 'Conditions Générales d’Utilisation'
};
