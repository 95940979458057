import ClientOAuth2 from 'client-oauth2';
import { getCookie, setCookie } from './utils';
import { Config } from './types';
import { axios } from '@/plugins/axios';
import { router } from '@/router';
import { TokenEnum } from '@/types/enum/TokenEnum';
import { store } from '@/store';
import { RolesEnum } from '@/types/enum/RolesEnum';
import userInfoService from '@/services/userInfo/userInfoService';
import { AUTHORIZATION_URI, LOGOUT_URI, USER_INFO_URI } from '@/constants/uri';

declare const OAUTH_URL: string;
declare const BFF_URL: string;
declare const CLIENT_ID: string;
declare const REDIRECT_URI: string;
/*
 * Nom du cookie par défaut dans lequel sera stocké le JSON Web Token
 */
let tokenCookieName = '';

export default class AuthenticatorJWT {
	private config: Config;
	private loginOAuth2Assure!: ClientOAuth2;
	private loginOAuth2Employeur!: ClientOAuth2;
	private loginOAuth2Temoin!: ClientOAuth2;

	public constructor(config: Config) {
		this.config = config;

		this.config.tokenCookieName = config.tokenCookieName || TokenEnum.TOKEN;

		tokenCookieName = this.config.tokenCookieName;

		const authorizationUri = OAUTH_URL + AUTHORIZATION_URI;
		// Création de l'instance OAuth2
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		this.loginOAuth2Assure = new ClientOAuth2({
			clientId: CLIENT_ID,
			authorizationUri,
			redirectUri: REDIRECT_URI,
			query: {
				userType: RolesEnum.ASSURE
			}
		});
		// @ts-ignore
		this.loginOAuth2Employeur = new ClientOAuth2({
			clientId: CLIENT_ID,
			authorizationUri,
			redirectUri: REDIRECT_URI,
			query: {
				userType: RolesEnum.EMPLOYEUR
			}
		});
		// @ts-ignore
		this.loginOAuth2Temoin = new ClientOAuth2({
			clientId: CLIENT_ID,
			authorizationUri,
			redirectUri: REDIRECT_URI,
			query: {
				userType: RolesEnum.TEMOIN
			}
		});

		// Si nous sommes déjà loggé (lors d'un rafraichissement de page par exemple), on redécore l'instance Axios
		if (this.isLoggedIn()) {
			if (this.config.axiosInstance) {
				this.decorateAxiosInstance();
			}
		}
	}

	/**
	 * Redirige l'utilisateur vers l'URL de connexion.
	 * L'utilisateur, une fois authentifié, sera redirigé vers l'URL
	 * spécifiée dans `config.redirectUri`
	 */
	public login(userType: string): void {
		switch (userType) {
			case RolesEnum.EMPLOYEUR:
				window.location.href = this.loginOAuth2Employeur.token.getUri();
				break;
			case RolesEnum.TEMOIN:
				window.location.href = this.loginOAuth2Temoin.token.getUri();
				break;
			default:
				router.push({
					name: 'loginAssure',
					params: {
						userType: userType
					}
				});
				break;
		}
	}

	/**
	 * Déconnecte l'utilisateur courant
	 */
	public logoutUri(userType: string, redirectUrl: string|null = null, tokenExpired = false): string {
		if (userType !== RolesEnum.ASSURE) {
			const redirectUri = redirectUrl ? redirectUrl : REDIRECT_URI;

			return OAUTH_URL + '/' + userType + LOGOUT_URI + '?client_id=' + CLIENT_ID + '&redirect_uri=' + redirectUri;
		} else {
			let token = null;
			if (!tokenExpired) {
				token = this.getToken(tokenCookieName) ?? this.getToken(TokenEnum.INSCRIPTION_TOKEN);
			}

			return BFF_URL + '/fc/logout?token=' + token + '&id_token=' + this.getToken(TokenEnum.ID_TOKEN_HINT);
		}

	}

	public logout(userType: string, tokenExpired = false): void {
		this.deleteUserInfoAndDispatchLogoutEvents();
		// On déconnecte si c'est une demande de l'utilisateur et seulement si c'est via France Connect pour les assurés et peu importe pour les employeurs / témoins
		// Et on doit rediriger vers la page d'accueil dans les autres cas et si session expirée
		if ((userType === RolesEnum.ASSURE && this.getToken(TokenEnum.ID_TOKEN_HINT) != null) || !tokenExpired || userType !== RolesEnum.ASSURE) {
			window.location.href = this.logoutUri(userType, null, tokenExpired);
		} else if (tokenExpired) {
			this.login(userType);
		}
		this.clearStorage();
	}

	public deleteUserInfoAndDispatchLogoutEvents(): void {
		userInfoService.deleteUserInfo();
		store.dispatch('appState/updateLoaded', false);
		store.dispatch('appState/updateLogged', false);
		store.dispatch('appState/updateUser', null);
	}

	public clearStorage(): void {
		this.removeToken();
		sessionStorage.clear();
	}

	/**
	 *  supprime le JSON Web Token enregistré en cookie
	 */
	public removeToken(): void {
		setCookie(tokenCookieName, '', -1, false);
		if (this.getToken(TokenEnum.ID_TOKEN_HINT) ) {
			setCookie(TokenEnum.ID_TOKEN_HINT, '', -1, false);
		}
		if (this.getToken(TokenEnum.INSCRIPTION_TOKEN) ) {
			this.removeInscriptionToken();
		}
	}

	/**
	 *  supprime le Inscription Token enregistré en cookie
	 */
	public removeInscriptionToken(): void {
		setCookie(TokenEnum.INSCRIPTION_TOKEN, '', -1, false);
	}

	/* eslint-disable */
	/**
	 * Fonction qui doit être appelée par vos soins sur la page spécifiée
	 * dans `config.redirectUri`. Cette fonction va récupérer les données de l'URL
	 * (le hash #...) et les stocker en cookie
	 */
	public loginCallback(callback?: () => void, errorCb?: (error: any) => void): any {
		const currentURL = `${window.location.protocol}//${window.location.host + window.location.pathname + window.location.search + window.location.hash}`;
		this.loginOAuth2Assure.token
			.getToken(currentURL)
			.then((user) => {
				this.saveToken(tokenCookieName, user.accessToken, false);

				// Si on a déclaré une instance Axios, on la décore
				if (this.config.axiosInstance) {
					this.decorateAxiosInstance();
				}

				if (typeof callback === 'function' && callback) {
					callback();
				}
			})
			.catch((error) => {
				if (errorCb) {
					errorCb(error.response);
				} else {
					throw new Error(error);
				}
			});
	}

	/**
	 * Renvoie `true` si l'utilisateur est connecté, `false` sinon.
	 *
	 * @returns {Boolean} `true` si l'utilisateur est connecté, `false` sinon.
	 */
	public isLoggedIn(): boolean {
		return this.getToken(tokenCookieName) !== null;
	}

	/**
	 * Fonction qui renvoie le token persisté dans un cookie, ou `null` s'il n'existe pas.
	 *
	 * @param {string} name Nom du cookie
	 *
	 * @returns {string | null} Le token ou `null` s'il n'existe pas
	 */
	public getToken(name: string): string | null {
		const token = getCookie(name);

		return token !== '' && token !== undefined ? token : null;
	}


	/**
	 * Fonction qui effectue une requète GET sur le userinfo du serveur d'authentification
	 * puis appelle le callback avec la réponse
	 */
	public getUserInfo(
		callback: (response: any) => void,
		errorCb: (error: any) => void
	): any {
		if (this.config.axiosInstance) {
			this.config.axiosInstance
				.get(USER_INFO_URI)
				.then((response) => callback(response.data))
				.catch((error) => errorCb(error.response));
		}
	}

	/**
	 * Fonction qui décore une instance axios pour lui ajouter :
	 *  - Les entètes de requêtes qui permettent d'authentifier chaque requête
	 *  - Les catch des réponses indiquant que le token n'est plus valide qui vont automatiquement le rafraichir
	 *
	 */
	public decorateAxiosInstance(): any {
		if (!this.config.axiosInstance) {
			return;
		}
		// On ajoute le token d'authentification dans les headers de chaque requête
		this.config.axiosInstance.defaults.headers.common.authorization = 'Bearer ' + this.getToken(tokenCookieName);
		axios.defaults.headers.common.authorization = 'Bearer ' + this.getToken(tokenCookieName);
	}

	/**
	 * Fonction qui persiste le token dans un cookie.
	 *
	 * @param {string} name Nom du cookie
	 * @param {string} token Le token à persister.
	 * @param {boolean} secure.
	 */
	public saveToken(name: string, token: string, secure: boolean): void {
		if (token && token !== '') {
			// On ne sait pas l'expiration du token, car géré côté API.
			setCookie(name, token, null, secure);
		}
	}
}
