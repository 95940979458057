import layout from './layout';
import notification from './notification';
import password from './password';
import gestionUtilisateurs from './gestionUtilisateurs';
import gestionSirets from './gestionSirets';
import cederSirets from './cederSirets';
import motDePasse from './motDePasse';
import adresseEmail from './adresseEmail';
import confirmFileDialog from './confirmFileDialog';
import confirmEmailDialog from './confirmEmailDialog';
import authentification from './authentification';
import questionnaire from './questionnaire';

export default {
	layout,
	notification,
	password,
	gestionUtilisateurs,
	cederSirets,
	gestionSirets,
	motDePasse,
	adresseEmail,
	confirmFileDialog,
	confirmEmailDialog,
	authentification,
	questionnaire
};
