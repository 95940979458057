import { RawLocation, Route } from 'vue-router';
import { LocalStorageUtility } from '@cnamts/vue-dot/src/helpers/localStorageUtility';
import { EXPIRATION } from '@/constants/time';

export const localStorageNameRedirect = 'redirectTo';
export const localStorageNameRedirectLoggedOut = 'redirectToLogout';
const version = parseInt(process.env.VUE_APP_VERSION ? process.env.VUE_APP_VERSION : '0', 10);
const routeService = {

	localStorageUtility: new LocalStorageUtility(version,EXPIRATION),

	// détermine si local storage redirectTo existe
	hasRedirectTo(localStorageName: string = localStorageNameRedirect): boolean {
		const to = this.localStorageUtility.getItem(localStorageName);
		return to !== null;
	},
	// sauvegarde la route demandée avant la connexion dans le local storage
	saveRedirectTo(to: Route, localStorageName: string = localStorageNameRedirect): void {
		if (to) {
			this.localStorageUtility.setItem(localStorageName, to);
		}
	},
	getRedirectTo(localStorageName: string = localStorageNameRedirect): Route | null {
		// si le redirectTo existe, on le récupère
		if (this.hasRedirectTo()) {
			return this.localStorageUtility.getItem(localStorageName);
		}
		return null;
	},
	// supprime le redirectTo dans le local storage
	deleteRedirectTo(localStorageName: string = localStorageNameRedirect): void {
		this.localStorageUtility.removeItem(localStorageName);
	},
	// Enregistre une redirection forcée au mode déconnecté
	saveRedirectToLoggedOut(to: RawLocation): void {
		if (to) {
			this.localStorageUtility.setItem(localStorageNameRedirectLoggedOut, to);
		}
	},
	getRedirectToLoggedOut(): RawLocation | null {
		// si le redirectTo existe, on le récupère
		if (this.hasRedirectTo(localStorageNameRedirectLoggedOut)) {
			return this.localStorageUtility.getItem(localStorageNameRedirectLoggedOut);
		}
		return null;
	}
};

export default routeService;
