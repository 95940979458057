import axios, { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios';

import { store } from '@/store';

import { mdiAlert as errorIcon } from '@mdi/js';

import common from '@/translations/fr/common';

import { router } from '@/router';

import userInfoService from '@/services/userInfo/userInfoService';

import AuthenticatorJWT from '@/authenticator-jwt';

import { LocalStorageEnum } from '@/types/enum/LocalStorageEnum';

import { RolesEnum } from '@/types/enum/RolesEnum';
import { HttpCodesEnum } from '@/types/enum/HttpCodesEnum';
import { NOTIFICATION_PATH } from '@/constants/uri';

/** API_URL env var */
declare const API_URL: string;
declare const OAUTH_URL: string;
declare const BFF_URL: string;
declare const API_KEY: string;

const headers: AxiosRequestHeaders = {
	'Accept': 'application/json',
	'Content-Type': 'application/json'
};

/** The axios instance */
const instance = axios.create({
	withCredentials: false,
	baseURL: API_URL,
	headers: headers
});

/** The axios instance */
const oAuthInstance = axios.create({
	withCredentials: false,
	baseURL: OAUTH_URL,
	headers: headers
});

headers['x-api-key'] = API_KEY;

/** The axios instance */
const instanceBff = axios.create({
	withCredentials: false,
	baseURL: BFF_URL,
	headers: headers
});

/** Default error message */
const DEFAULT_ERROR_MESSAGE = common.defaultErrorMessage;
const DEFAULT_OAUTH_MESSAGE = common.oauthErrorMessage;

function handle401Error(error: AxiosError, type: string): string {
	let errorMessage = '';
	if (error.response?.data.message === 'cgu.invalid') {
		const userInfo = userInfoService.getUserInfo();
		if (userInfo !== null && router.currentRoute.name !== 'cgu') {
			router.push({ name: 'updateCgu' });
		}
	} else if (error.response?.data.message === 'Authentication Required') {
		if (type === RolesEnum.ASSURE) {
			localStorage.setItem(LocalStorageEnum.TOKEN_EXPIRED, 'true');
		} else {
			errorMessage = DEFAULT_OAUTH_MESSAGE;
		}
		Authenticator.logout(type, true);
	}
	return errorMessage;
}

function handle403Error(error: AxiosError, type: string): string {
	if (type === RolesEnum.TEMOIN && error.response?.data.message === 'Access Denied.') {
		Authenticator.logout(type);
		return '';
	}
	return error.response?.data.message || DEFAULT_ERROR_MESSAGE;
}

// Response interceptor to handle errors globally
instance.interceptors.response.use(undefined, (error: AxiosError) => {
	let errorMessage = '';
	const type = userInfoService.getUserInfo()?.type ?? RolesEnum.ASSURE;
	// If we don't have an error or a 500 HTTP Code
	if (!error.response || error.response.status >= HttpCodesEnum.HTTP_SERVER_ERROR) {
		errorMessage = DEFAULT_ERROR_MESSAGE;
	} else if (error.response.status === HttpCodesEnum.HTTP_UNAUTHORIZED_ERROR) {
		errorMessage = handle401Error(error, type);
	} else if (error.response.status === HttpCodesEnum.HTTP_FORBIDDEN_ERROR) {
		errorMessage = handle403Error(error, type);
	} else {
		// Else, use message from server or fallback to default one
		errorMessage = error.response.data.message || DEFAULT_ERROR_MESSAGE;
	}

	if (errorMessage) {
		// Send notification error
		store.dispatch(NOTIFICATION_PATH, {
			type: 'error',
			message: errorMessage,
			icon: errorIcon
		});
	}

	return Promise.reject(error);
});

// Response interceptor to handle errors globally
oAuthInstance.interceptors.response.use(undefined, (error: AxiosError) => {
	return interceptError(error);
});

// Response interceptor to handle errors globally
instanceBff.interceptors.response.use(undefined, (error: AxiosError) => {
	return interceptError(error);
});

function interceptError(error: AxiosError) {
	let errorMessage: string;

	// If we don't have a error or a 500 HTTP Code
	if (!error.response || error.response.status >= HttpCodesEnum.HTTP_SERVER_ERROR) {
		// Use the default message
		errorMessage = DEFAULT_ERROR_MESSAGE;
	} else {
		// Else, use message from server or fallback to default one
		errorMessage = error.response.data.message || DEFAULT_ERROR_MESSAGE;
	}

	if (errorMessage) {
		// Send notification error
		store.dispatch(NOTIFICATION_PATH, {
			type: 'error',
			message: errorMessage,
			icon: errorIcon
		});
	}

	return Promise.reject(error);
}

export const Authenticator = new AuthenticatorJWT({
	axiosInstance: oAuthInstance
});

export { instance as axios };
export { instanceBff as axiosBff };
export { AxiosResponse };
