import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import { RootState } from '../types';
import { MessageAdminModel } from '@/services/folders/types';
import { getMessagesUtilisateurs } from '@/services/folders/api';

export interface MessageUtilisateursState {
	messages: MessageAdminModel[]|null;
}
const state: MessageUtilisateursState = {
	/** Is the application loaded */
	messages: null
};

const actions: ActionTree<MessageUtilisateursState, RootState> = {
	async updateMessageUtilisateurs({ commit }) {
		getMessagesUtilisateurs()
			.then((response) => {
				commit('UPDATE_MESSAGE', response.data);
			})
			.catch();
	}
};

const mutations: MutationTree<MessageUtilisateursState> = {
	UPDATE_MESSAGE(state, messages) {
		state.messages = messages;
	}
};

const getters: GetterTree<MessageUtilisateursState, RootState> = {};

const messageUtilisateurs: Module<MessageUtilisateursState, RootState> = {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
};

export default messageUtilisateurs;
