export default {
	sansQuestionnaires: 'Ce dossier ne comporte pas de questionnaire',
	telechargerPieces: 'Télécharger toutes les pièces dossier',
	telechargerQuestionnaires: 'Télécharger le questionnaire vierge | Télécharger les questionnaires vierges',
	telechargerAttestationTemoin: 'Télécharger Attestation Témoin',
	statut: 'Statut du dossier',
	noCompteAssure: 'Le salarié n\'a pas encore crée son compte',
	lien: 'lien',
	attenteÉtapeConsultation1: '',
	attenteÉtapeConsultation2: 'Le dossier sera disponible pour consultation et commentaires à compter du',
	compléterQuestionnaire1: '',
	compléterQuestionnaire2: 'Vous pouvez répondre au(x) questionnaire(s) jusqu\'au',
	consulterEtCommenter1: 'Votre dossier est consultable',
	consulterEtCommenter2:
	'Vous pouvez prendre connaissance des pièces et au besoin y apporter des commentaires à partir du {dateDebut} et jusqu\'au {dateFin}',
	attenteDécisionCpam1:
	'Le dossier est en cours d\'instruction',
	attenteDécisionCpam2:
	'La période de commentaires est terminée. une décision vous sera apportée au plus tard le',
	compléterDossier1: 'Compléter dossier CRRMP',
	compléterDossier2: 'Vous pouvez ajouter des pièces et déposer vos commentaires à partir du {dateDebut} et jusqu\'au {dateFin}',
	commenterDossierCrrmp1: 'Commenter dossier CRRMP',
	commenterDossierCrrmp2: 'Vous pouvez déposer vos commentaires jusqu\'au',
	instructionCrrmp1: 'En attente instruction CRRMP',
	instructionCrrmp2:
	'La période d\'ajout de pièces et commentaires est terminée. Une décision vous sera apportée au plus tard le',
	dossierArchivé1: 'Une décision a été apportée',
	dossierArchivé2: 'Le dossier est désormais archivé et restera accessible jusqu\'au',
	aucune1: 'Aucune action a réaliser',
	aucune2: ''
};
