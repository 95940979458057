
	import Vue from 'vue';
	import Component from 'vue-class-component';

	import HeaderMenu from './HeaderMenu.vue';

	declare const MAINTENANCE: string;

	/** The Header of the application */
	@Component({
		components: {
			HeaderMenu
		}
	})
	export default class AppHeader extends Vue {
		get link(): string|boolean {
			return this.maintenance ? false : this.$t('components.layout.appHeader.logoBtn.link') as string;
		}

		get maintenance(): boolean {
			return MAINTENANCE === 'true';
		}
	}
