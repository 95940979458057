import { UserInfo } from '@/types';
import { MILLISECONDS_PER_DAY } from '@/constants/time';

const userInfoService = {
	// sauvegarde un cookie avec les infos de l'utilisateur
	saveUserInfo(userInfo: UserInfo): void {
		const d = new Date();
		d.setTime(d.getTime() + MILLISECONDS_PER_DAY);
		const expires = 'expires=' + d.toUTCString();
		const jsonUserInfo = JSON.stringify(userInfo);
		document.cookie = `userInfoCookie=${jsonUserInfo};${expires};path=/`;
	},
	getUserInfo(): UserInfo | null {
		// si le user info existe, on le récupère
		if (this.hasUserInfo()) {
			const name = 'userInfoCookie' + '=';
			// on parcourt la liste des cookie
			const cookieSplitted = document.cookie.split(';');
			let userinfoJson = '';

			for (const part of cookieSplitted) {
				const cookiePart = part.trim();
				// si notre cookie commence par name, c'est celui du userinfo
				if (cookiePart.startsWith(name)) {
					// on récupère le contenu
					userinfoJson = cookiePart.substring(name.length, cookiePart.length);
				}
			}

			return JSON.parse(userinfoJson);
		}

		return null;
	},
	// supprime un cookie avec les infos utilisateurs
	deleteUserInfo(): void {
		const d = new Date();
		d.setTime(d.getTime() - MILLISECONDS_PER_DAY);
		const expires = 'expires=' + d.toUTCString();
		document.cookie = `userInfoCookie=;${expires};path=/`;
	},
	// détermine si le cookie utilisateur existe
	hasUserInfo(): boolean {
		const dc = document.cookie;
		const regex = /;*userInfoCookie=/g;

		return dc.search(regex) !== -1;
	}
};

export default userInfoService;
