import { axios, AxiosResponse } from '@/plugins/axios';
import { FormValues } from '@cnamts/form-builder/src/functions/getFormValues/types';
import { FieldList } from '@cnamts/form-builder/src/components/FormFieldList/types';
import { QrpQuestionnaireModel, QuestionnaireModel } from '@/services/questionnaire/types';

/** service for call the users api */

export const questionnaireService = {
	async getQuestionnaires(): Promise<AxiosResponse<string[]>> {
		return axios
			.get('/api/questionnaires');
	},

	async getQrpQuestionnaire(reponseId: string): Promise<AxiosResponse<QrpQuestionnaireModel>> {
		return axios
		.get(`/api/qrp_questionnaires/${reponseId}`);
	},
	async getForm(
		questionnaireId: string,
		responseUnit: string | null,
		step: string | null
	): Promise<AxiosResponse<string[]>> {
		return axios
			.get(`/api/questionnaires/${questionnaireId}/form?${responseUnit ? 'reponse_unit=' + responseUnit : '' }
			${step ? '&etape=' + step : '' }`);
	},
	async getRecap(
		questionnaireId: string,
		responseUnit: string
	): Promise<AxiosResponse<FieldList[]>> {
		return axios
		.get(`/api/questionnaires/${questionnaireId}/reponses/units/${responseUnit}/recap`);
	},
	async postForm(
		questionnaireId: string,
		responses: FormValues | null,
		responseUnit: string | null,
		step: string | null
	): Promise<AxiosResponse<string[]>> {
		return axios
			.post(`/api/questionnaires/${questionnaireId}/form?${responseUnit ? 'reponse_unit=' + responseUnit : '' }
			${step ? '&etape=' + step : '' }`, responses);
	},

	async submitResponses(
		questionnaireId: string,
		responses: FormValues,
		respondentId: string | null,
		step: string | null): Promise<AxiosResponse<string[]>> {
		if (!respondentId) {
			return axios.post(`/api/questionnaires/${questionnaireId}/reponses`, responses);
		} else {
			return axios.put(`/api/questionnaires/${questionnaireId}/reponses/${respondentId}${step
				? '?etape=' + step
				: '' }`, responses
			);
		}
	},

	async validateQuestionnaire(questionnaireId: string): Promise<AxiosResponse<string[]>> {
		return axios.post(`/api/qrp_questionnaires/${questionnaireId}/validate`, {});
	},

	async getPiece(idPiece: string, sendMail = 'false'): Promise<AxiosResponse<string>> {
		return axios.get('/downloadAttachment/file/' + idPiece + '?sendMail=' + sendMail, {
			responseType: 'blob'
		});
	},

	async getPieceQuestionnaire(idQuestionnaire: string): Promise<AxiosResponse<string>> {
		return axios.get('/download/qrp_questionnaire/valide/' + idQuestionnaire, {
			responseType: 'blob'
		});
	},

	async getPieceDirect(fileReference: string): Promise<AxiosResponse<string>> {
		return axios.get('/downloadDirect/file/piece/' + fileReference, {
			responseType: 'blob'
		});
	},

	async uploadFile(file: File, uploadRoute: string): Promise<AxiosResponse<string[]>> {
		const formData = new FormData();
		formData.append('file1', file as File);

		return axios.post(`/files/upload/${uploadRoute}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		);
	},

	async getAllEmptyRecap(dossierId: string): Promise<AxiosResponse<string>> {
		return axios.get('/acteur/download/dossier/files/' + dossierId, {
			responseType: 'blob'
		});
	},

	async getAllPieces(dossierId: string, sendMail = 'false'): Promise<AxiosResponse<string>> {
		return axios.get('/download/all/files/dossier/' + dossierId + '?sendMail=' + sendMail, {
			responseType: 'blob'
		});
	},

	async getIdQuestionnaireTemoin(): Promise<AxiosResponse<QuestionnaireModel>> {
		return axios.get('/api/questionnaire-temoin');
	},

	async getDocumentProvisoire(
		questionnaireId: string,
		responseUnit: string
	): Promise<AxiosResponse<string>> {
		return axios.get(`/pdf/document-provisoire/${questionnaireId}/${responseUnit}`, {
			responseType: 'blob'
		});
	}
};
