export default {
	user: 'Utilisateur',
	role: 'Développeur',
	logout: 'Déconnexion',
	links: [
		{
			title: 'Accueil',
			to: {
				name: 'home'
			},
			disabled: false
		}
	]
};
