import { MILLISECONDS_PER_HOUR } from '@/constants/time';
// Fonctions génériques d'aides non spécifiques à authenticator-jwt

function base64urlEscape(str: string) {
	return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}

export function base64urlEncode(str: string): string {
	return base64urlEscape(Buffer.from(str).toString('base64'));
}

/* eslint-disable */
/**
 * Décode un JSON Web Token et retourne son contenu sous forme d'objet
 *
 * @param {String} token Le JSON Web Token
 * @returns {Object} Le contenu du JSON Web Token
 */
export function parseJwt(token: string): any {
	const base64Url = token.split('.')[1];

	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

	return JSON.parse(window.atob(base64));
}

/**
 * Déclare un cookie
 *
 * @param {String} cookieName Le nom du cookie à déclarer
 * @param {String} cookieValue Le contenu du cookie à déclarer
 * @param {Number} expireHours Le nombre d'heures de validité du cookie
 * @param {boolean} isSecure
 */
export function setCookie(cookieName: string, cookieValue: string, expireHours: number|null, isSecure: boolean): void {
	let expires = '';
	if (expireHours !== null) {
		const d = new Date();
		d.setTime(d.getTime() + (expireHours * MILLISECONDS_PER_HOUR));
		expires = 'expires=' + d.toUTCString()+';';
	}
	const secure = isSecure ? 'secure' : '';
	document.cookie = `${cookieName}=${cookieValue};${expires}path=/;${secure}`;
}

/**
 * Récupère le contenu d'un cookie
 *
 * @param {String} cookieName Le nom du cookie à récupérer
 * @returns {String} Le contenu du cookie
 */
export function getCookie(cookieName: string): string {
	const name = cookieName + '=';
	const ca = document.cookie.split(';');

	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];

		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}

		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}

	return '';
}

/**
 * Récupère le contenu d'un paramètre hash
 *
 * @param {String} name Le nom du paramètre hash à récupérer
 * @returns {String | null} Le contenu du paramètre hash
 */
export function getHashParameterByName(name: string): string | null {
	const match = RegExp('[#&]' + name + '=([^&]*)').exec(window.location.hash);

	if (!match) {
		return null;
	}

	return decodeURIComponent(match[1].replace(/\+/g, ' '));
}
