/*
 Here are a example service to illustrate
 how to do requests with Axios in a service

 - The function getAll shows how to do a GET request
 - The function updateStatus shows how to do a POST request with a parameter
   in the URL and a payload
*/

import { axios, AxiosResponse } from '@/plugins/axios';
import { DataPagination } from 'vuetify';
import { DossierModel, PieceJointeModel } from '@/services/questionnaire/types';
import { CommentaireModel, FiltresModel } from '@/services/folders/types';

interface Payload {
	example: string;
}

export function getAll(pagination: DataPagination): Promise<AxiosResponse> {
	return axios.get('/api/all', {
		params: {
			pagination
		}
	});
}

export function updateStatus(id: string, payload: Payload): Promise<AxiosResponse> {
	return axios.post(`/api/${id}/status`, payload);
}

export function getDossiers(): Promise<AxiosResponse<DossierModel[]>> {
	return axios.get('/api/acteur-dossiers');
}

export function getDossier(id: string): Promise<AxiosResponse> {
	return axios.get(`/api/details-dossier-acteur/${id}`);
}

export function generateDateActionActeur(): Promise<AxiosResponse> {
	return axios.post('/api/generate/date-action-acteur');
}

export function getPaginatedDossiers(
	page: number,
	itemsPerPage: number,
	siret = '',
	filters: FiltresModel | null = null
): Promise<AxiosResponse> {
	let url = `/api/acteur-dossiers?page=${page}&itemsPerPage=${itemsPerPage}&order[echeance.dateEcheanceActeur]=asc`;

	if (siret !== '') {
		url += `&employeurs.siret=${siret}`;
	}

	if (filters !== null) {
		if (filters.echeanceStart && filters.echeanceEnd) {
			url += `&echeance.dateEcheanceActeur[after]=${filters.echeanceStart}&echeance.dateEcheanceActeur[before]=${filters.echeanceEnd}`;
		}

		if (filters.actionsType) {
			filters.actionsType.forEach((action) => {
				url += `&echeance.actionActeur[]=${action}`;
			});
		}
	}

	return axios.get(`${url}`, {
		headers: {
			accept: 'application/ld+json'
		}
	});
}

export function commenterPieceDossier(payload: CommentaireModel): Promise<AxiosResponse> {
	return axios.post('/api/commentaires', payload);
}

export function uploadFile(file: File): Promise<AxiosResponse> {
	const formData = new FormData();
	formData.append('filename', file as File);

	return axios.post('/files/upload/qrp_attachment', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}
	);
}

export function addFile(file: PieceJointeModel): Promise<AxiosResponse> {
	return axios.post('/api/piece_dossiers', file);
}

export function getTypePiece(typePiece: string): Promise<AxiosResponse> {
	return axios.get(`/api/type_pieces?libelleCourt=${typePiece}`);
}

export function getMessagesUtilisateurs(): Promise<AxiosResponse> {
	return axios.get('/api/message_utilisateurs', {
		headers: {
			accept: 'application/json'
		}
	});
}
