export default {
	versionLabel: 'Version',
	aide: 'Besoin d\'aide ?',
	annuler: 'Annuler',
	textConnecte: 'Si vous avez besoin d\'un renseignement, merci de joindre le numéro suivant :',
	textNonConnecte: 'Si vous avez besoin d\'un renseignement, merci de joindre un des numéros suivants :',
	numeroEmployeur: '36.79',
	numeroAssure: '36.46',
	employeur: 'Employeur : ',
	assure: 'Assure / Témoin : '
};
