export default {
	enregistrer: 'Enregistrer nouvelle adresse email',
	newEmail: 'Nouvelle adresse email',
	newEmailConfirmation: 'Confirmer votre nouvelle adresse email',
	newEmailInfo: 'Pour permettre le changement d\'adresse vous serez ensuite déconnecté.',
	emailMismatch: 'Adresses saisies différentes.',
	email: 'Adresse email',
	titre: 'Adresse email',
	modifier: 'Modification de l\'adresse email'
};
