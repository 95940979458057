
	import Vue from 'vue';
	import Component from 'vue-class-component';

	import { RawLocation } from 'vue-router';

	import userInfoService from '@/services/userInfo/userInfoService';
	import { Authenticator } from '@/plugins/axios';
	import { mapActions, mapState } from 'vuex';
	import { getMyUser } from '@/services/compte/api';
	import { LocalStorageEnum } from '@/types/enum/LocalStorageEnum';

	interface LinkItem {
		title: string;
		disabled: boolean;
		to?: RawLocation;
	}

	/** User profile button in the HeaderBar */
	@Component({
		computed: mapState('appState', ['user', 'logged']),
		methods: mapActions('appState', [
			'updateLoaded',
			'updateUser',
			'updateLogged'
		])
	})
	export default class HeaderMenu extends Vue {

		get user(): string {
			const user = this.$store.state.appState.user;
			return user ? user.email : '';
		}

		get role(): string {
			const user = this.$store.state.appState.user;

			if (user.roles.includes('ROLE_ASSURE')) {
				return 'Salarié';
			} else if (user.roles.includes('ROLE_EMPLOYEUR')) {
				return 'Employeur';
			} else if (user.roles.includes('ROLE_TEMOIN')) {
				return 'Témoin';
			}

			return '';
		}

		get links(): LinkItem[] {
			return this.$t('components.layout.headerMenu.links') as unknown as LinkItem[];
		}

		logout(): void {
			// On fait un appel api pour vérifier si le token est expiré.
			const userType = userInfoService.getUserInfo()?.type ?? 'assure';
			if (userType !== 'temoin') {
				getMyUser()
					.then(() => {
						this.logoutUser(userType);
					});
			} else {
				this.logoutUser(userType);
			}
		}

		logoutUser(userType: string): void {
			localStorage.clear();
			localStorage.setItem(LocalStorageEnum.DECONNEXION, 'true');
			Authenticator.logout(userType);
		}

		login(userType: string): void {
			Authenticator.login(userType);
		}

		connexion(): string {
			return this.logged ? this.user + ' ' + this.role : 'Connexion';
		}
	}
