
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import { mapState } from 'vuex';

	/** Home page */
	@Component<Home>({
		computed: mapState('appState', ['user'])
	})
	export default class Home extends Vue {
		mounted(): void {
			if (this.user) {
				let route = 'dossierAssure';
				if (this.user.type === 'employeur') {
					route = 'dossierEmployeur';
				}
				this.$router.push({
					name: route
				});
			}
		}
	}
