export default {
	password: 'Mot de passe',
	passwordVerification: 'Vérification mot de passe',
	caracteres: 'Au moins 8 caractères',
	nombres: 'Au moins 2 nombres',
	special: 'Au moins 1 caractère spécial (@$!%*#?&)',
	forgottenTitre: 'Mot de passe oublié',
	forgottenTexte: 'Renseignez l\'adresse mail associée à votre compte afin de réinitialiser votre mot de passe.',
	email: 'Adresse email',
	sendMailTitre: 'Consultez vos emails',
	sendMailTexte1: 'Si l\'adresse indiquée est associée à un compte, vous allez recevoir un lien pour réinitialiser votre mot de passe. Cliquez dessus afin de pouvoir définir un nouveau mot de passe.',
	sendMailTexte2: 'Vous n\'avez pas reçu d\'email ? Vérifiez vos spams ou ',
	sendMailLien: 'envoyer à nouveau un lien de réinitialisation',
	tokenErrorTitre: 'Réinitialisation du mot de passe',
	tokenErrorExpired: 'La durée de validité de votre demande de réinitialisation de mot de passe a expiré.',
	tokenErrorInvalid: 'Le jeton de connexion de votre demande de réinitialisation de mot de passe est invalide.',
	tokenErrorTexte: 'Vous pouvez renouveler votre demande ou retourner à l\'accueil.',
	resetTitre: 'Créer un nouveau mot de passe',
	resetedPasswordTitre: 'Réinitialisation du mot de passe',
	resetedPasswordTexte: 'Votre mot de passe a bien été modifié.',
	retour: 'Retour',
	envoyer: 'Envoyer',
	connexion: 'Connexion',
	renouveler: 'Renouveler votre demande',
	reinitialiser: 'Réinitialiser le mot de passe'
};
