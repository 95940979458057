export default {
	meta: {
		title: 'CGU – Questionnaires Risques Professionnels',
		description: 'CGU – Questionnaires Risques Professionnels'
	},
	accept: 'Accepter',
	return: 'Retour',
	description: 'Afin d’utiliser le téléservice Questionnaire Risques Professionnels (QRP), merci de lire et d’accepter les',
	notification: 'Attention, nous vous rappelons qu\'aucune pièce à caractère médical ne doit être transmise via ce téléservice.',
	notification2: 'A défaut, le secret médical sera considéré comme levé et la responsabilité de la Caisse Nationale d\'Assurance Maladie ne saurait être engagée conformément aux CGU.',
	cancel: 'Annuler et me déconnecter',
	updateTitle: 'Mise à jour de nos '
};
