import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import { RootState } from '../types';
import { UserInfo } from '@/types';

export interface AppState {
	loaded: boolean;
	logged: boolean;
	user: UserInfo | null;
}

const state: AppState = {
	/** Is the application loaded */
	loaded: false,
	logged: false,
	user: null
};

const actions: ActionTree<AppState, RootState> = {
	updateLoaded({ commit }, loaded) {
		commit('UPDATE_LOADED', loaded);
	},
	updateLogged({ commit }, logged) {
		commit('UPDATE_LOGGED', logged);
	},
	updateUser({ commit }, user) {
		commit('UPDATE_USER', user);
	}
};

const mutations: MutationTree<AppState> = {
	UPDATE_LOADED(state, loaded: boolean) {
		state.loaded = loaded;
	},
	UPDATE_LOGGED(state, logged: boolean) {
		state.logged = logged;
	},
	UPDATE_USER(state, user: UserInfo) {
		state.user = user;
	}
};

const getters: GetterTree<AppState, RootState> = {};

const appSate: Module<AppState, RootState> = {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
};

export default appSate;
